import axios from 'axios'
import React, { useState } from 'react'
import { Col, Form, Modal, Row, Button, Container } from 'react-bootstrap'
import styles from '../login.module.scss'
import Image from 'next/image'

function ResetPassword({ show, handleClose, handleCloseForgetPwd, handleCloseRegister }) {
  const [showSuccess, setShowSuccess] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [email, setEmail] = useState('')
  // console.log("called")
  function ValidateEmail(inputText) {
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (inputText.match(mailformat)) {
      // alert("Valid email address!");
      // document.form1.text1.focus();
      return true
    } else {
      setErrorText('You have entered an invalid email address!')
      return false
    }
  }
  const sendResetEmail = async () => {
    if (email.length > 0) {
      if (ValidateEmail(email)) {
        const resetPwd = await axios.get(`https://api.collegepass.org/api/v1/forgotPassword/${email}`)
        // console.log("called")
        // console.log(resetPwd)
        if (resetPwd.data.status) {
          handleClose()
          setShowSuccess(true)
        } else {
          // console.log(resetPwd)
          setErrorText(resetPwd.data.data)
        }
      }
    } else setErrorText('Please enter an email address')
  }
  return (
    <>
      <Container fluid>
        <Row
          className={styles.wrapLogin}
          // onClick={(e) => {
          //   // e.stopPropagation()
          //   // window.open('/', '_self')
          //   handleClose()
          //   handleCloseForgetPwd()
          //   handleCloseRegister()
          //   console.log('Details:, clicked ooutside of the boxx')
          // }}
        >
          <Col>
            <Container>
              <Row>
                <Col className={styles.bigImg}>
                  <Row>
                    <Col className="pt-2">
                      <span
                        className="cursor-pointer"
                        onClick={() => {
                          handleClose()
                          handleCloseForgetPwd()
                          handleCloseRegister()
                        }}
                      >
                        <Image
                          src="/logo/cp_text_logo.png"
                          alt="Text Logo"
                          width="180"
                          height="52"
                          className={styles.footerLogo}
                        />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Row>
              <Col>
                <Row className={styles.loginSectionWrap}>
                  <Col className={styles.loginSectionWidth}>
                    <Row className={styles.title}>
                      <Col>
                        {/* <i
                      className="fa fa-times"
                      aria-hidden="true"
                      onClick={() => {
                        handleCloseForgetPwd()
                        handleCloseRegister()
                        handleClose()
                      }}
                    ></i> */}
                        <h1>Reset password</h1>
                      </Col>
                    </Row>
                    <Form className="new-signin-form">
                      <Row>
                        <Col>
                          <Form.Group className="mb-3 new-signin-txtbx" controlId="formBasicEmail">
                            {/* <Form.Label>Email</Form.Label> */}
                            <Form.Control
                              type="email"
                              required={true}
                              placeholder="Enter your email"
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value)
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <p
                        style={{
                          textAlign: 'center',
                          color: 'red',
                          marginTop: '-2%',
                        }}
                      >
                        {errorText}
                      </p>
                      <Row>
                        <Col className="text-center">
                          <Button onClick={sendResetEmail} className="width-100 new-signin-btnsbmt">
                            Send email
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showSuccess}
        onHide={() => {
          setShowSuccess(false)
        }}
        className={styles.loginModal}
      >
        <Modal.Header closeButton className="border-bottom-none reset-pass-modal mt-2">
          <Row className="width-100">
            <Col className="text-center">
              <Row>
                <Col className={styles.titleModal}>
                  <h4>Email sent</h4>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form>
                <p
                  className="text-center"
                  style={{
                    color: '#ffffff',
                    opacity: '0.8',
                  }}
                >
                  Password recovery link has been sent.
                </p>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ResetPassword
